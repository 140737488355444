import React, { useState, useEffect } from "react";
import { Button, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Table } from "reactstrap";
import { CATEGORIAS_DESPESAS } from "../../../options";
import BlocoCategoria from "./BlocoCategoria";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import {
  getAxiosAuth,
  wasRequestCancelled,
  getClienteId,
  toBRL,
} from "../../../utils";
import { DateCarousel, TagsFilterSelector } from "../../../components";
import { withRouter } from "react-router-dom";
import { ELSFilterSelector } from "../../../components/ELSFilterSelector";
function Despesas(props) {
  const m = moment();
  const [totalDespesas, setaTotalDespesas] = useState(0.0);
  const [categorias, setaCategorias] = useState([]);
  const [searchTotalDespesas, setaSearchTotalDespesas] = useState(0.0);
  const [searchCategorias, setaSearchCategorias] = useState([]);
  const [mes, setaMes] = useState(Number(m.format("M")));
  const [ano, setaAno] = useState(Number(m.format("YYYY")));
  const [inseriu, setaInseriu] = useState(null);
  const [atualizou, setaAtualizou] = useState(null);
  const [removeu, setaRemoveu] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [tags, setaTags] = useState([]);
  const [ELS, setaELS] = useState([]);
  const [recorrenteFilter, setaRecorrenteFilter] = useState(false);
  const [temporariaFilter] = useState(false);
  const [importacaoFilter, setaImportacaoFilter] = useState(false);
  const [patrimoniosFilter, setaPatrimoniosFilter] = useState(false);
  const [searchTerm,setSearchTerm] = useState("");
  
  const onAdd = () => {
    setaInseriu(new Date());
  };

  const onEdit = () => {
    setaAtualizou(new Date());
  };

  const onDelete = () => {
    setaRemoveu(new Date());
  };

  const getDespesas = async () => {
    const clienteId = getClienteId();
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });
    const uri = `/clientes/${clienteId}/despesas`;
    const dados = { 
      filtroMes: mes, 
      filtroAno: ano, 
      filtroTags: tags ? 
      tags.map((tag) => tag.value) : [],
      filtroTipo: ELS?
      ELS.map((els)=>els.value) :[],
      filtroRecorrente: recorrenteFilter ? recorrenteFilter : false,
      filtroTemporaria: temporariaFilter ? temporariaFilter : false,
      filtroImportacao: importacaoFilter ? importacaoFilter : false,
      filtroPatrimonio: patrimoniosFilter ? patrimoniosFilter : false,
      organize: 1 };
      
    let response;

    setIsLoading(true);

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      props.history.push("/500");
      return;
    }

    setIsLoading(false);

    if (response.data && "totalDespesas" in response.data) {
      setaTotalDespesas(response.data.totalDespesas);
    }

    if (response.data && "categorias" in response.data) {
      setaCategorias(response.data.categorias);
    }
  };

  useEffect(() => {
    if (inseriu) {
      toast("Despesa adicionada!");
      getDespesas();
    }
  }, [inseriu]);

  useEffect(() => {
    if (atualizou) {
      toast("Despesa atualizada!");
      getDespesas();
    }
  }, [atualizou]);

  useEffect(() => {
    if (removeu) {
      toast("Despesa removida!");
      getDespesas();
    }
  }, [removeu]);

  useEffect(() => {
    getDespesas(); // primeiro acesso, e quando altera data
  }, [mes, ano, tags, ELS, recorrenteFilter , temporariaFilter, importacaoFilter, patrimoniosFilter]);

  const blocosCategorias = CATEGORIAS_DESPESAS.map((categoria) => {
    let c = searchTerm ? searchCategorias : categorias

    const dados = c.find(
      (categoriaDado) => categoriaDado.nome === categoria.nome
    );

    return (
      <BlocoCategoria
        key={`despesas_${categoria.nome}`}
        mes={mes}
        ano={ano}
        tags={tags}
        onAdd={onAdd}
        onEdit={onEdit}
        onDelete={onDelete}
        isLoading={isLoading}
        {...categoria}
        {...dados}
      />
    );
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchOpen, setSeachOpen] = useState(false);

  const handleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const handleSearch = () =>{
    setSeachOpen(!searchOpen);
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedSearch = debounce(async (searchValue) => {
    const clienteId = getClienteId();
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });
    const uri = `/clientes/${clienteId}/despesas`;
    let response;

    const dados = { 
      filtroMes: mes, 
      filtroAno: ano, 
      filtroTags: tags ? 
      tags.map((tag) => tag.value) : [],
      filtroTipo: ELS?
      ELS.map((els)=>els.value) :[],
      filtroRecorrente: recorrenteFilter ? recorrenteFilter : false,
      filtroTemporaria: temporariaFilter ? temporariaFilter : false,
      filtroImportacao: importacaoFilter ? importacaoFilter : false,
      filtroPatrimonio: patrimoniosFilter ? patrimoniosFilter : false,
      organize: 1, 
      searchValue: searchValue
    };
      
    setIsLoading(true);

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      props.history.push("/500");
      return;
    }

    setIsLoading(false);

    if (response.data && "totalDespesas" in response.data) {
      setaSearchTotalDespesas(response.data.totalDespesas);
    }

    if (response.data && "categorias" in response.data) {
      setaSearchCategorias(response.data.categorias);
    }
  }, 500);

  useEffect(() => {
    if (searchTerm !== '') {
      debouncedSearch(searchTerm);
    }
  }, [searchTerm]);


  return (
    <div className="animated fadeIn">
      <Container>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          style={{ zIndex: 1049 }}
        />
        <div className="despesas-container">
          <div className="header-container">
            <div style={{ display: "flex", flexWrap: "wrap" }} className="filter-item">
              <div className="date-carousel-despesas">
                <DateCarousel mes={mes} ano={ano} onChange={(newMes, newAno) => { setaMes(newMes); setaAno(newAno); }} />
              </div>
              <div className="filters-despesas-container">
                <div>
                  {searchOpen?<Input onChange={(e) => setSearchTerm(e.target.value)} type="search"/>:null}
                </div>
                <div>          
                  <Button 
                  isOpen={searchOpen} 
                  onClick={handleSearch} 
                  style={{marginRight:"20px"}}>
                    <i className="fa fa-search"/>
                  </Button>
                </div>
                <div className="filters-pop-up">
                  <Dropdown isOpen={dropdownOpen} onClick={handleDropdown}>
                    <DropdownToggle caret>
                      Filtros
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                        <TagsFilterSelector tagShow={false} onChange={setaTags} tags={tags} className="tags-filter" />
                      </DropdownItem>
                      <DropdownItem>
                        <ELSFilterSelector onChange={setaELS} ELS={ELS} />
                      </DropdownItem>
                      <DropdownItem>
                        <FormGroup check className="checkbox-label" style={{ marginTop: "6px" }}>
                            <Input type="checkbox" checked={patrimoniosFilter} onChange={() => setaPatrimoniosFilter(!patrimoniosFilter)} />
                            <Label check>Patrimonio</Label>
                        </FormGroup>
                      </DropdownItem>
                      <DropdownItem>
                        <FormGroup check className="checkbox-label" style={{ marginTop: "6px" }}>
                          <Input type="checkbox" checked={recorrenteFilter} onChange={() => setaRecorrenteFilter(!recorrenteFilter)} />
                          <Label check>Recorrente</Label>
                        </FormGroup>
                      </DropdownItem>
                      <DropdownItem>
                        <FormGroup check className="checkbox-label">
                          <Input type="checkbox" checked={importacaoFilter} onChange={() => setaImportacaoFilter(!importacaoFilter)} />
                          <Label check>Importação</Label>
                        </FormGroup>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <Table size="sm" hover>
              <thead className="thead-dark">
                  <tr>
                    <th
                      className="text-left"
                      style={{ paddingLeft: 10, width: 60 }}
                    />
                    <th className="text-left">Total Despesas</th>
                    <th className="d-none d-sm-table-cell" style={{ width: 20 }} />
                    <th className="d-none d-sm-table-cell" style={{ width: 20 }} />
                    <th className="d-none d-sm-table-cell" style={{ width: 20 }} />
                    <th className="text-right text-nowrap" style={{ width: 120 }}>
                      {toBRL(searchTerm ? searchTotalDespesas : totalDespesas)}
                    </th>
                    <th className="d-none d-sm-table-cell" style={{ width: 100 }} />
                  </tr>
                </thead>
            </Table>
          </div>
          <div className="scrollable-content">
            <Table size="sm" hover>
              {blocosCategorias}
            </Table>
          </div>
        </div>
      </Container>
    </div>
  );
  
}

export default withRouter(Despesas);
