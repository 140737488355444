import React, { useState, useEffect } from "react";
import { Button, Container, Table, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label } from "reactstrap";
import { toBRL, getClienteId } from "../../../utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { getAxiosAuth, wasRequestCancelled } from "../../../utils";
import { DateCarousel, TagsFilterSelector } from "../../../components";
import { withRouter } from "react-router-dom";
import BtnModalFormReceita from "./BtnModalFormReceita";
import LinhaReceita from "./LinhaReceita";
import { TIPOS_RECEITAS } from "../../../options";
import { ELSFilterSelector } from "../../../components/ELSFilterSelector";

const TipoFilterSelector = ({ onChange, selectedTipo }) => {
  const handleClick = (tipo, e) => {
    e.preventDefault();
    const updatedTipos = selectedTipo.includes(tipo)
      ? selectedTipo.filter(item => item !== tipo)
      : [...selectedTipo, tipo];
    onChange(updatedTipos);
  };

  return (
    <FormGroup>
      {TIPOS_RECEITAS.map((tipo) => (
        <button
          key={tipo}
          className={`btn btn-outline-primary m-1 ${selectedTipo.includes(tipo) ? "btn-primary active" : ""}`}
          onClick={(e) => handleClick(tipo, e)}
        >
          {tipo}
        </button>
      ))}
    </FormGroup>
  );
};

const filterReceitas = (receitas, filters) => {
  const {
    selectedTipo,
    tags,
    ELS,
    recorrenteFilter,
    patrimoniosFilter,
    importacaoFilter,
    searchTerm
  } = filters;

  return receitas.filter(receita => {
    const matchesTipo = selectedTipo.length === 0 || selectedTipo.includes(receita.tipo);
    const matchesTags = tags.length === 0 || (receita.tags && receita.tags.some(receitaTag => 
      tags.some(filterTag => filterTag.value === receitaTag.id)
    ));
    const matchesELS = ELS.length === 0 || ELS.some(els => els.value === receita.tipo);
    const matchesRecorrente = !recorrenteFilter || receita.rendaFixa;
    const matchesPatrimonios = !patrimoniosFilter || receita.patrimonioId;
    const matchesImportacao = !importacaoFilter || receita.importacaoId;
    const matchesSearchTerm = !searchTerm || receita.descricao.toLowerCase().includes(searchTerm.toLowerCase());

    return matchesTipo && matchesTags && matchesELS && matchesRecorrente && matchesPatrimonios && matchesImportacao && matchesSearchTerm;
  });
};

function Receitas(props) {
  const m = moment();
  const [totalReceitas, setaTotalReceitas] = useState(0.0);
  const [totalDeducoes, setaTotalDeducoes] = useState(0.0);
  const [totalLiquido, setaTotalLiquido] = useState(0.0);
  const [receitas, setaReceitas] = useState([]);
  const [mes, setaMes] = useState(Number(m.format("M")));
  const [ano, setaAno] = useState(Number(m.format("YYYY")));
  const [inseriu, setaInseriu] = useState(null);
  const [atualizou, setaAtualizou] = useState(null);
  const [removeu, setaRemoveu] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTipo, setaSelectedTipo] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [tags, setaTags] = useState([]);
  const [ELS, setaELS] = useState([]);
  const [recorrenteFilter, setaRecorrenteFilter] = useState(false);
  const [temporariaFilter] = useState(false);
  const [importacaoFilter, setaImportacaoFilter] = useState(false);
  const [patrimoniosFilter, setaPatrimoniosFilter] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchOpen, setSeachOpen] = useState(false);
  const handleDropdown = () => setDropdownOpen(!dropdownOpen);

  const onAdd = () => {
    setaInseriu(new Date());
  };

  const onEdit = () => {
    setaAtualizou(new Date());
  };

  const onDelete = () => {
    setaRemoveu(new Date());
  };

  const onChangeDate = (mes, ano) => {
    setaMes(mes);
    setaAno(ano);
  };

  const getReceitas = async () => {
    const clienteId = getClienteId();
    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });
    const uri = `/clientes/${clienteId}/receitas`;
    const dados = {
      filtroMes: mes,
      filtroAno: ano,
      filtroTipos: selectedTipo.length ? selectedTipo : null,
      filtroTags: tags ? tags.map((tag) => tag.value) : [],
      filtroTipo: ELS ? ELS.map((els) => els.value) : [],
      filtroRecorrente: recorrenteFilter ? recorrenteFilter : false,
      filtroTemporaria: temporariaFilter ? temporariaFilter : false,
      filtroImportacao: importacaoFilter ? importacaoFilter : false,
      filtroPatrimonio: patrimoniosFilter ? patrimoniosFilter : false,
      organize: 1,
      searchValue: searchTerm
    };
    setIsLoading(true);

    try {
      const response = await axiosInstance.get(uri, { params: dados });
      if (response.data && "totalReceitas" in response.data) {
        setaTotalReceitas(response.data.totalReceitas);
      }
      if (response.data && "totalDeducoes" in response.data) {
        setaTotalDeducoes(response.data.totalDeducoes);
      }
      if (response.data && "totalLiquido" in response.data) {
        setaTotalLiquido(response.data.totalLiquido);
      }
      if (response.data && "items" in response.data) {
        setaReceitas(response.data.items);
      }
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error("Erro na requisição:", err);
      }
      props.history.push("/500");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = () => {
    setSeachOpen(!searchOpen);
  };

  useEffect(() => {
    if (inseriu) {
      toast("Receita adicionada!");
      getReceitas();
    }
  }, [inseriu]);

  useEffect(() => {
    if (atualizou) {
      toast("Receita atualizada!");
      getReceitas();
    }
  }, [atualizou]);

  useEffect(() => {
    if (removeu) {
      toast("Receita removida!");
      getReceitas();
    }
  }, [removeu]);

  useEffect(() => {
    getReceitas();
  }, [mes, ano, selectedTipo, tags, ELS, recorrenteFilter, temporariaFilter, importacaoFilter, patrimoniosFilter, searchTerm]);

  const filteredReceitas = filterReceitas(receitas, {
    selectedTipo,
    tags,
    ELS,
    recorrenteFilter,
    patrimoniosFilter,
    importacaoFilter,
    searchTerm
  });

  return (
    <div className="animated fadeIn">
      <Container className="receitas-container">
        <ToastContainer position="top-right" autoClose={3000} style={{ zIndex: 1049 }} />
        <DateCarousel mes={mes} ano={ano} onChange={onChangeDate} />
        <BtnModalFormReceita mes={mes} ano={ano} onAdd={onAdd} />
        <div className="filters-despesas" style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <div>
              {searchOpen ? <Input onChange={(e) => setSearchTerm(e.target.value)} type="search"/> : null}
            </div>
            <div>
              <Button isOpen={searchOpen} onClick={handleSearch}>
                <i className="fa fa-search"/>
              </Button>
            </div>
            <div className="filters-pop-up">
              <Dropdown isOpen={dropdownOpen} onClick={(e) => {
                e.preventDefault();
                handleDropdown();
              }}>
                <DropdownToggle caret>Filtros</DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onMouseOver={(e) => e.preventDefault()} onMouseOut={(e) => e.preventDefault()}>
                    <TipoFilterSelector onChange={setaSelectedTipo} selectedTipo={selectedTipo} />
                  </DropdownItem>
                  <DropdownItem onMouseOver={(e) => e.preventDefault()} onMouseOut={(e) => e.preventDefault()}>
                    <TagsFilterSelector tagShow={false} onChange={setaTags} tags={tags} className="tags-filter" />
                  </DropdownItem>
                  <DropdownItem onMouseOver={(e) => e.preventDefault()} onMouseOut={(e) => e.preventDefault()}>
                    <ELSFilterSelector onChange={setaELS} ELS={ELS} />
                  </DropdownItem>
                  <DropdownItem>
                    <FormGroup check className="checkbox-label" style={{ marginTop: "6px" }}>
                      <Input type="checkbox" checked={patrimoniosFilter} onChange={() => setaPatrimoniosFilter(!patrimoniosFilter)} />
                      <Label check>Patrimonio</Label>
                    </FormGroup>
                  </DropdownItem>
                  <DropdownItem>
                    <FormGroup check className="checkbox-label" style={{ marginTop: "6px" }}>
                      <Input type="checkbox" checked={recorrenteFilter} onChange={() => setaRecorrenteFilter(!recorrenteFilter)} />
                      <Label check>Recorrente</Label>
                    </FormGroup>
                  </DropdownItem>
                  <DropdownItem>
                    <FormGroup check className="checkbox-label">
                      <Input type="checkbox" checked={importacaoFilter} onChange={() => setaImportacaoFilter(!importacaoFilter)} />
                      <Label check>Importação</Label>
                    </FormGroup>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
        <Table size="sm" hover>
          <thead className="thead-dark">
            <tr>
              <th className="text-left" colSpan={2}>Total Receitas</th>
              <th className="d-none d-sm-table-cell" style={{ width: 20 }} />
              <th className="d-none d-sm-table-cell" style={{ width: 20 }} />
              <th className="d-none d-sm-table-cell" style={{ width: 20 }} />
              <th className="text-right text-nowrap" style={{ width: 120 }}>
                {toBRL(totalReceitas)}
              </th>
              <th className="d-none d-sm-table-cell" style={{ width: 80 }} />
            </tr>
            <tr>
              <th className="text-left text-small" colSpan={2}>Deduções</th>
              <th className="d-none d-sm-table-cell" />
              <th className="d-none d-sm-table-cell" />
              <th className="d-none d-sm-table-cell" />
              <th className="text-right text-nowrap text-danger">
                <em>({toBRL(totalDeducoes)})</em>
              </th>
              <th className="d-none d-sm-table-cell" />
            </tr>
            <tr>
              <th className="text-left" colSpan={2}>Total Líquido</th>
              <th className="d-none d-sm-table-cell" />
              <th className="d-none d-sm-table-cell" />
              <th className="d-none d-sm-table-cell" />
              <th className="text-right text-nowrap">{toBRL(totalLiquido)}</th>
              <th className="d-none d-sm-table-cell" />
            </tr>
          </thead>
          {isLoading && (
            <tbody>
              <tr className="table-success">
                <td colSpan={6}>
                  <div className="text-muted text-center mt-3">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Carregando...</span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          )}
          {!isLoading && receitas.length === 0 && (
            <tbody>
              <tr className="table-success">
                <td colSpan={6}>
                  <p className="text-muted text-center mt-3">
                    Sem receitas no período.
                  </p>
                </td>
              </tr>
            </tbody>
          )}
          {!isLoading &&
            filteredReceitas.length > 0 &&
            filteredReceitas.map((receita) => (
              <LinhaReceita
                key={`receita_` + receita.id}
                onEdit={onEdit}
                onDelete={onDelete}
                {...receita}
              />
            ))}
        </Table>
      </Container>
    </div>
  );
}

export default withRouter(Receitas);
