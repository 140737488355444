import React from 'react';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";

function Monetary(props) {
  const {
    value,
    onChange,
    onBlur,
    valid,
    invalid,
    readOnly,
    ...otherProps
  } = props;

  const formatCurrency = (inputValue) => {
    const numericValue = inputValue.replace(/[^\d]/g, '');
    
    if (!numericValue) return '0,00';
    
    const paddedValue = numericValue.padStart(3, '0');
    
    const integerPart = paddedValue.slice(0, -2).replace(/^0+/, '');
    const decimalPart = paddedValue.slice(-2);

    const formattedInteger = integerPart 
      ? integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      : '0';
    
    return `${formattedInteger},${decimalPart}`;
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatCurrency(inputValue);

    if (onChange) {
      event.target.value = formattedValue;
      onChange(event);
    }
  };

  const handleBlur = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatCurrency(inputValue);
    
    if (onBlur) {
      event.target.value = formattedValue;
      onBlur(event);
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 32) {
      event.preventDefault();
    }

    const allowedKeys = [
      'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 
      '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'
    ];
    
    if (!allowedKeys.includes(event.key) && !event.metaKey && !event.ctrlKey) {
      event.preventDefault();
    }
  };

  return (
    <InputGroup className="monetary" {...otherProps}>
      <InputGroupAddon addonType="prepend">
        <InputGroupText>R$</InputGroupText>
      </InputGroupAddon>
      <Input
        type="text"
        className="text-right"
        value={value ? formatCurrency(value) : '0,00'}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        placeholder={'0,00'}
        valid={valid}
        invalid={invalid}
        readOnly={readOnly}
      />
    </InputGroup>
  );
}

export default Monetary;